

.loan-form h1 {
    /* font-weight: 700; */
    font-size: 36px;
    margin-bottom: 20px;
    color: #0D9068;
}

.loan-form .card {
    width: 250px;
    background: rgba(13, 144, 104, 0.25);
    border-radius: 12px;
    margin: auto 8px;
    padding: 5px;
}

.category-form-group input,
.category-form-group input:hover,
.category-form-group input:focus {

    width: auto;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 25px;
    color: #E98923;
    background-color: transparent;
}

.loan-form .card p {
    font-size: 20px;
    text-align: center;

    color: #444444;
}

.loan-form .card input::placeholder {
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    color: #E98923
}

.loan-form .card input::-webkit-outer-spin-button,
.loan-form .card input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.loan-form .eye-icon {
    width: 25px;
    height: 25px;
}

.loan-form .card p:last-child {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #444444;
}

.loan-form p.note {
    /* width: 90%; */
    font-weight: 400;
    font-size: 13px;
    color: #444444;

}

.loan-input-form input,
.loan-input-form select,
.loan-input-form input:hover {
    max-width: 100%;
    height: 45px;
    width: 380px;
    border: 0.3px solid #909090;
    border-radius: 4px;
    margin: 15px 0;
    padding: 10px;
    color: #444444;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.7;
}

.loan-input-form input::placeholder,
.loan-input-form select option {
    font-weight: 400;
    font-size: 14px;
    color: #444444; 
}
.loan-input-form input:focus,
.loan-input-form select:focus{
    outline: none;

}
.loan-input-form input::-webkit-outer-spin-button,
.loan-input-form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.loan-input-form select {
    appearance: none;
    background-image: url('../../../../assets/filled-arrow.png');
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: 1em auto, 100%;


}
.loan-form .submit-btn {
    width: 180px;
    height: 45px;
    background: #FB9129;
    border-radius: 4px;
    font-size: 20px;
    color: #FFFFFF;
}

.loan-form .modal {
    z-index: 999;
}

.loan-form .modal-base {
    top: 15%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fefefe;
    margin: auto;
    width: 800px;
    height: 400px;
    border-radius: 22px;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}



@media screen and (max-width: 800px) {
    .loan-form {
    margin: 70px 0 0 0;
    }
}



@media (min-width: 579px) and (max-width: 600px) {
    .loan-form h1 {
        font-size: 30px;
    }

    .loan-form .submit-btn {
        width: 100px;
    }

}

@media (max-width: 578.98px) {
    .loan-form h1 {
        font-size: 30px;
    }
    .loan-form .submit-btn {
        width: 100px;
    }
}

@media (min-width: 450px) and (max-width: 577.98px) {
    .loan-form h1 {
        font-size: 28px;
    }

    .loan-form .submit-btn {
        width: 100px;
    }
}

@media (max-width: 426.98px) {
    .loan-form h1 {
        font-size: 26px;
    }
    .loan-form .card {
        margin: 0 10px 0 0;
    }
    .loan-form .savings-wallet-category .card, .schedule-savings-page .card {
        flex: 0 0 200px;

    }
    .loan-form p.note {
        text-align: justify;
    }
}

@media screen and (max-width: 376px) {
    .content {
        /* padding: 10px 0; */
    }

    .loan-form h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .guarantor-account-form p {
        font-size: 18px;
        margin: 10px;
    }

    .loan-form .submit-btn {
        width: 100px;
        font-size: 18px;
    }


}

@media screen and (max-width: 321px) {
    .loan-form h1 {
        font-size: 20px;
    }

    .loan-form .submit-btn {

        font-size: 16px;
    }
}