.sidebar-user {
  position: fixed;
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #0D9068;
  box-shadow: 0px 4px 15px 0px #0000000D;
  border: 1px solid #44444440;


}

.profile-img-container,
.sidebar-user-menu {
  overflow-y: hidden;
}

.mobile-sidebar-menu {
  display: none;
}

.hamburger-icon {
  display: none;
  z-index: 990;
  color: #f99c39;
  font-size: 35px;
  
}

.sidebar-user .profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;

}

.sidebar-user .btn.camera {
  position: absolute;
  left: 85px;
  top: 70px;
  background: #FFFFFF;
  border: 1px solid rgba(68, 68, 68, 0.16);
  border-radius: 50%;
  padding: 2px;
  display: flex;
}

.sidebar-user .camera .camera-icon {
  height: 16px;
  width: 16px;
}

.sidebar-user .profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-info p {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
  overflow-y: hidden;
}

.sidebar-user-menu a {
  text-decoration: none;
}

.sidebar-user-menu li {
  margin: 10px 0;

}

.sidebar-user-menu .link,
.sidebar-user-menu .dropdown-title,
.sidebar-user-menu .logout {
  width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  white-space: nowrap;
  padding: 5px 0;
}

.sidebar-user-menu .link:hover,
.sidebar-user-menu .dropdown-title:hover,
.sidebar-user-menu .logout:hover {
  background: rgba(20, 20, 20, 0.5);
  color: #FFFFFF;
  cursor: pointer;
}

.sidebar-user .sidebar-user-menu .link.active {
  background: rgba(20, 20, 20, 0.3);
  font-weight: 600;
  /* font-size: 16px; */
  border-left: 5px solid #FB9129;
  padding-left: 1.2rem !important;
}

.sidebar-user .sidebar-user-menu.mobile a.active {
  background: transparent;
  font-weight: 600;
  color: #FB9129;
  border: 0;
  padding-left: 0 !important;
}


.sidebar-user-menu .sidebar-icon {
  font-size: 25px;
  margin-right: 20px;
}

.sidebar-user-menu .arrow-icon {
  font-size: 25px;
}


.sidebar-user-menu.mobile {
  display: none;
}






@media screen and (max-width: 800px) {



  .admin.hamburger-icon {
    /* ... Existing styles ... */
    cursor: pointer;
  }

  .sidebar-user .profile-info {
    display: none;
  }

  .sidebar-user-menu.desktop {
    display: none;
  }

  .sidebar-user-menu.mobile {
    /* display: block;
    margin-top: 50px; */
    display: flex;
    flex-direction: column;
    /* margin-top: 50px; */
    height: 100vh;
    justify-content: center;

  }

  .hamburger-icon {
    /* display: block;
    position: absolute;
    left: 20px;
    transform: scaleX(-1); */
    display: block;
    position: fixed;
    transform: scaleX(1);
    top: 0;
    left: 0;
    right: 0;
    padding: 0 10px;
    /* width: 100%; */
    font-size: 50px;
    background-color: #fff;
  }

  .sidebar-user {
    background-color: transparent;
    width: 0;
  }

  .sidebar-user.open {
    background-color: #0D9068;
    z-index: 999;
    width: 100%;

  }

  .content {
    margin-left: 0 !important;
  }

  .sidebar-user-content {
    margin: 0 auto;
  }

  .sidebar-user-menu.mobile li a,
  .sidebar-user-menu .logout {
    justify-content: center;
  }
}

@media (min-width: 426px) and (max-width: 575.98px) {
  .content {
      padding: 17px;
  }
  .hamburger-icon {
    left: 17px;
    padding: 0 10px;
}
}

@media screen and (max-width: 769px) {
 
}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 426px) {
  .hamburger-icon {
    padding: 0 10px;
}
}

@media screen and (max-width: 376px) {}

@media screen and (max-width: 321px) {}