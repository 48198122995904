.help .contact .help-icons {
    width: 25px;
    height: auto;
    color: #0D9068;
}

.help .contact p {
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    margin-bottom: 0;
}
.email, .phone{
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
}
.content {
    /* padding: 0px; */
}

.contact .phone p.span {
    font-size: 13px;
}

.help .contact a {
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
    text-align: justify;

    color: #0D9068;
}

.help .contact-form p {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}

.help .contact-form input {
    max-width: 100%;
    width: 400px;
    height: 50px;
    border: 1px solid #797979;
    border-radius: 4px;
    padding: 10px;
    margin: 0 0 20px 0;

}

.help .contact-form textarea {
    max-width: 100%;
    width: 400px;
    height: 150px;
    border: 1px solid #797979;
    border-radius: 4px;
    padding: 10px;
}

.help .contact-form input::placeholder,
.help .contact-form textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    text-align: justify;

    color: #000000;
}

.help .contact-form input:focus,
.help .contact-form textarea:focus {
    outline: none;
    /* border: none; */

}

.help .contact-form .btn {
    width: 180px;
    padding: 10px;
    float: right;
    background: #FB9129;
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
}




@media screen and (max-width: 1024px) {
    
    .help .contact a {
        font-size: 20px;
        text-wrap: nowrap;
}
.help .contact p.href {
    text-wrap: nowrap;
}
.help .contact-form p {
    text-align: center;
}
.help .contact .help-icons {
    width: 30px;
}
}
@media screen and (max-width: 991px) {}
@media screen and (max-width: 769px) {}
@media screen and (max-width: 600px) {
    .help .contact-form p {
        text-align: left;
    }
    .contact-form{
        margin-top: 30px;
    }
    .phone{
        margin-top: 20px !important;
    }
    .help .contact-form input, .help .contact-form textarea {
        width: inherit;
    }
    
}
@media screen and (max-width: 426px) {
    
    .help .contact p {
        font-size: 16px;
    }
    .help .contact a {
        font-size: 18px;
    }
    .help .contact .help-icons {
        width: 25px;
    }
    .help .contact-form .btn {
        width: fit-content;
        padding: 10px 20px;
    }
}
@media screen and (max-width: 376px) {}
@media screen and (max-width: 321px) {
    .help .contact p {
        font-size: 14px;
    }
    .help .contact a {
        font-size: 16px;
        /* text-wrap: wrap; */
    }
    .contact .phone p.span {
        font-size: 10px;
    }
    .email, .phone {
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        flex-direction: column;
    }
    .help .contact-form .btn {
        width: fit-content;
        padding:8px 20px;
        font-size: 18px;
    }
}