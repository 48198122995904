.member {
  /* width: fit-content; */
  background: #FFFFFF;
  box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}

.member h1 {
  /* font-weight: 700; */
  font-size: 36px;
  color: #000000;
}


.member span {
  font-weight: 400;
  font-size: 18px;
  color: #FB9129;
  text-align: center;
}

.member p {
  width: auto;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000000;
}

form.row {
  align-items: center;
  justify-content: center;
  width: fit-content;
  --bs-gutter-x: 0;
}

/* .member input,
.member select {
  width: 350px;
  height: 50px;
  margin: 10px auto;
  padding: 8px;
  border: 1px solid #444444;
  border-radius: 4px;
  color: #444444;
  font-size: 15px;
} */

/* new css */

.custom-gutter {
  margin: 0 4rem;

}

.custom-gutter [class^='col-'] {
  margin: 15px 0;
}


.member input,
.member select {
  max-width: 80%;
  width: 400px;
  height: 50px;
  padding: 8px;
  border: 1px solid #444444;
  border-radius: 4px;
  color: #444444;
  font-size: 15px;
}

.member select::placeholder {
  color: #444444;
}
.member .password {
  /* max-width: 80%; */
  /* border: 1px solid #444444;
  width: 350px;
  padding: 0; */
  border: 1px solid #444444;
    width: 375px;
    padding: 0;
    margin: 0 auto;
    border-radius: 4px;
}
.member .password .icon-div {
  flex: 1;
}
.member .password .eye-icon {
  cursor: pointer;
}
.member .password input {
  flex:5;
  outline: none;
  border: none;
}

/* .member input::placeholder::after {
  content: '*';
  color: red;
} */
.member input::placeholder,
.member select option {
  font-weight: 400;
  font-size: 14px; 
  color: #444444;
}

.member select {
  appearance: none;
  background-image: url('../../../../assets/outline-arrow.png');
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: 0.9em auto, 100%;
  


}

.member input:focus,
.member select:focus {
  outline: none;
  border-color: #FB9129;
  
}
.member select.selected::placeholder {
  color: #000; /* Change color to black */
}

.member input::-webkit-outer-spin-button,
.member input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.member-btn {
  width: 320px;
  height: auto;
  padding: 10px;
  background: #0D9068;
  border-radius: 55px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #FFFFFF;
}

.member-btn:hover {
  background-color: #ffffff;
  color: #0D9068;
  border: 2px solid #0D9068;
}

.member p.password-match {
  float: right;
  color: red;
  width: auto;
  margin: 0;
  font-size: 13px
}









@media (max-width: 1025px) {
  .member .password {
    width: 280px;
  }
}



/* CSS for screens between 768px and 900px */
@media (min-width: 769px) and (max-width: 900px) {
  .custom-gutter {
    margin: 0rem;
  }
}
@media (max-width: 601px) {
  .member .password {
    width: 375px;
  }
}
@media (max-width: 568px) {
  .member .password {
    width: 320px;
  }
}


/* CSS for screens between 576px and 767.98px */
@media (min-width: 577px) and (max-width: 768.98px) {
  .member h1 {
    font-size: 30px;
  }

  .custom-gutter {
    margin: 0rem;
  }

  .member-btn {
    width: 250px;
    height: 50px;
  }
}

/* CSS for screens up to 575.98px */
@media (min-width: 427px) and (max-width: 576.98px) {
  .member-btn {
    width: 250px;
    height: 50px;
    padding: 5px;
  }
  
}

/* Small Devices (Phones) */
@media (min-width: 376px) and (max-width: 426.98px) {
  .member h1 {
    font-size: 26px;
  }

  .member span {
    font-size: 16px;
  }
  .member .password {
    width: 280px;
  }

  .custom-gutter {
    margin: 0rem;
  }

  .member input,
  .member select {
    width: 350px;
  }

  .member-btn {
    width: 200px;
    height: auto;
    font-size: 20px;
    padding: 5px;
  }
}

@media (max-width: 375.98px) {
  .member h1 {
    font-size: 24px;
  }

  .member span {
    font-size: 14px;
    width: 75%;
  }

  .member p {
    width: 75%;
  }

  .custom-gutter {
    margin: 0rem;
  }

  .member input,
  .member select {
    width: 350px;
  }

  .member-btn {
    width: 200px;
    height: auto;
    font-size: 18px;
    padding: 5px;
  }
  .member .password {
    width: 280px;
  }

}

@media (max-width: 321px) {

  .member input,
  .member select {
    width: 320px;
  }
  .member .password {
    width: 255px;
  }
}