

.landing-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  text-align: center;
}

.back-to-homepage {
  position: absolute;
  top: 20px;
  left: 20px;
}

.landing-page a {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #0D9068;
  text-decoration: none;
}

.landing-page h1 {
  font-style: normal;
  /* font-weight: 400; */
  font-size: 80px;
  text-align: center;
  color: #FFFFFF;
}

.landing-page .btn {
  width: 250px;
  height: 80px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-radius: 50px;
  font-weight: 500;
  margin: auto 40px;
  font-size: 25px;
  text-align: center;
  color: #FFFFFF;
  text-wrap: nowrap;
}

.landing-page .btn:hover {
  border: 2px solid;
  background-color: #ffffff !important;
}



.landing-page .btn:nth-child(1):hover {
  color: #555555;
  border-color: #555555;
}

.landing-page .btn:nth-child(2):hover {
  color: #FB9129;
  border-color: #FB9129;
}
















/* CSS for screens between 992px and 1199.98px */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .landing-page {
    /* Adjust styles for extra-large screens here */
  }

  .center-content {
    /* Adjust styles for extra-large screens here */
  }

  .back-to-homepage {
    /* Adjust styles for extra-large screens here */
  }
}

/* CSS for screens between 768px and 991.98px */
@media (min-width: 768px) and (max-width: 991.98px) {
  .landing-page h1 {
    font-size: 70px;
  }
}


/* CSS for screens between 576px and 767.98px */
@media (min-width: 576px) and (max-width: 767.98px) {
  .landing-page h1 {
    font-size: 55px;
  }

  .landing-page .btn {
    width: 210px;
    height: 60px;
    margin: auto 20px;
    font-size: 25px;
  }
}

/* CSS for screens up to 575.98px */
@media (min-width: 426px) and (max-width: 575.98px) {
  .landing-page h1 {
    font-size: 50px;
  }

  .landing-page .btn {
    width: 200px;
    height: 60px;
    margin: auto 20px;
    font-size: 25px;
  }
}

/* Small Devices (Phones) */
@media (min-width: 376px) and (max-width: 425.98px) {
  .landing-page h1 {
    font-size: 35px;
  }

  .landing-page .btn {
    width: 180px;
    height: 50px;
    margin: auto 10px;
    font-size: 20px;
  }
}

@media (max-width: 375.98px) {
  .landing-page a {
    font-size: 12px;
  }

  .landing-page h1 {
    font-size: 30px;
  }

  .landing-page .btn {
    width: 160px;
    height: 50px;
    margin: auto 8px;
    font-size: 20px;
  }

}

@media (max-width: 321px) {
  .button-element {
    flex-direction: column;

  }

  .landing-page h1 {
    font-size: 28px;
  }

  .landing-page .btn {

    margin: 8px auto;
    font-size: 18px;
  }
}







@media screen and (max-width: 1024px) {}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 769px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 426px) {}

@media screen and (max-width: 376px) {}

@media screen and (max-width: 321px) {}