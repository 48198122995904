.success-modal,
.otp-modal,
.add-savings-modal,
.transaction-success-modal,
.loan-summary-modal,
.loan-success-modal,
.profile-update-modal, .loan-summary-modal {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fefefe;
    margin: 0 auto;
    border-radius: 22px;
    /* border: none; */
    
}

.password-changed-modal {
    width: 587px;
    /* height: 500px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fefefe;
    margin: 0 auto;
    border-radius: 22px;

}

.success-modal h1 {
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    color: #3B3A3A;
    margin: 20px auto 5px auto;
}

.success-modal p,
.otp-modal p,
.add-savings-modal p {
    font-size: 16px;
    text-align: center;
    color: #3B3A3A;
    text-wrap: nowrap;
}

.profile-update-modal p {
    font-size: 16px;
    color: #000000;
}


.icon {
    width: 75px;
    height: 75px;
    color: #2F9909;
}

.success-modal .btn,
.otp-modal .btn {
    width: 300px;
    height: 60px;
    background: #FB9129;
    border-radius: 55px;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
}
.btn-modal.thank-btn {
    width: 100px;
    height: auto;
    padding: 0;
    background: #FB9129;
    border-radius: 50px;
}

.password-changed-modal .btn {
    width: 210px;
    height: 60px;
    background: #0D9068;
    border-radius: 55px;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
}

.otp-input input {
    width: 45px;
    height: 45px;
    background: rgba(13, 144, 104, 0.31);
    border: 0.5px solid #ADADAD;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    line-height: normal;
    margin: 1rem
}

.otp-input input::-webkit-outer-spin-button,
.otp-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add-savings-modal .btn {
    width: 180px;
 /* padding: 5px; */
    background: #FB9129;
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    text-wrap: nowrap;
}

.add-savings-modal span{
    text-align: center;
    
}

.add-savings-modal .add-savings-div,
.loan-summary-modal .loan-summary-div {
    /* width: 750px; */
}

.add-savings-modal a,
.loan-summary-modal a.back-to {
    font-weight: 500;
    font-size: 16px;
    float: left;
    color: #0D9068;
    text-decoration: none;
}

.add-savings-modal .add-icon,
.loan-summary-modal .add-icon {
    background: #0D9068;
    color: #ffffff;
    font-size: 20px;
    margin: 0 5px;
    border-radius: 50px;
}

.add-savings-modal p,
.transaction-success-modal p,
.loan-success-modal p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #000000;
}

.add-savings-modal input,
.add-savings-modal select {
    max-width: 100%;
    width: 350px;
    height: 50px;
    margin: 10px auto;
    padding: 8px;
    border: 1px solid #444444;
    border-radius: 4px;
    color: #444444;
    font-size: 15px;
}

.add-savings-modal input::placeholder,
.add-savings-modal select option {
    font-weight: 400;
    font-size: 16px;
    /* color: #444444; */
}

.add-savings-modal select {
    color: #727070;

}

.add-savings-modal input:focus,
.add-savings-modal select:focus,
.dashboard-form-input input:focus,
.dashboard-form-input select:focus {
    outline: none;

}

.add-savings-modal input::-webkit-outer-spin-button,
.add-savings-modal input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add-savings-modal select,
.dashboard-form-input select {
    appearance: none;
    background-image: url('../assets/outline-arrow.png');
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: 1em auto, 100%;


}
.add-savings-modal select,
.dashboard-form-input select {
    padding-right: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;

}



/* .add-savings-modal input::placeholder,
.add-savings-modal select option {
    font-size: 14px;
    color: #444444;
}

.add-savings-modal input::-webkit-outer-spin-button,
.add-savings-modal input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

.savings-modal-form-text{
    font-size: 18px;
}
.pay-option {
    width: 315px;
    background: #FFFFFF;
    border: 0.4px solid #C9C9C9;
    border-radius: 8px;
    margin: 15px 0;
    padding: 10px 0;
    color: #0D9068;
    /* padding: 1px; */
}

.pay-option:hover {
    background: #0D9068;
    color: #ffffff;
}

.pay-option .payment-icon {
    /* color: #0D9068; */
    margin: 0 5px;

    font-size: 25px;
}

.pay-option p:hover {
    color: #FFFFFF;
}

.pay-option p {
    font-size: 14px;
    font-weight: 500;
    text-align: justify;
    color: #000000;
    margin-bottom: 0;
}

.pay-option p span {
    font-weight: 300;
    font-size: 11px;
}

.transaction-success-modal .icon,
.loan-success-modal .icon {
    width: 60px;
    height: 60px;
    /* background-color: #0D9068; */
    color: #0D9068;
    border-radius: 50px;
}

.profile-update-modal .icon {
    /* width: 60px;
    height: 60px; */
    color: #444444;
    border-radius: 50px;
}



.transaction-success-modal span {
    font-weight: 600;
    font-size: 14px;
    color: #0D9068;
}

.transaction-success-modal .btn,
.loan-summary-modal .btn,
.loan-success-modal .btn,
.profile-update-modal .btn,
.transaction-success-modal .btn:focus,
.loan-summary-modal .btn:focus,
.loan-success-modal .btn:focus,
.profile-update-modal .btn:focus {
    width: 200px;
    /* height: 50px; */
    background: #FB9129;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    white-space: nowrap;
    padding: 10px;
    text-wrap: nowrap;
}

.loan-summary-modal h2, .savings-plan h4 {
    color: #0D9068;
    text-wrap: nowrap;
}

.loan-summary-modal p {
    font-weight: 400;
    font-size: 14px;
    color: #303030;
    margin-bottom: 0;

}
.loan-summary-modal hr {
    width: 100px;
    border: 1px solid #0D9068;
    margin: 0 8px;
}

.loan-summary-modal span {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    font-weight: 700;
    margin: 0 15px;
}
.loan-summary-modal span.status{
    width: 100px;
    padding: 5px;
    text-align: center;
border: 1px solid #0D9068;
border-radius: 33px;
font-weight: 500;
font-size: 14px;

color: #0D9068;

}
.loan-summary-modal a:nth-child(1) .btn,
.profile-update-modal .btn:first-child, .continue-saving .btn.change-card, .loan-summary-modal .btn-modal.edit, .add-savings-modal .btn-modal.cancel {
    background-color: #FFFFFF;
    color: #FB9129;
    border: 2px solid #FB9129;
}

.loan-summary-modal p.agree {
    font-weight: 400;
    font-size: 10px;
    color: #000000;
}

p.agree a {
    font-weight: 600;
    color: #000000;
}

.addcard-info {
    list-style-type: disc;
    list-style-position: inside;
}

.addcard-info li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 10px 0;
    color: #000000;
    display: list-item;
    list-style: disc;
    text-align: justify;
}


.savings-plan span{
    width: 400px;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
}




/* CSS for screens between 992px and 1199.98px */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* CSS for screens between 768px and 991.98px */
@media  (max-width: 800px) {
    
    .success-modal, .otp-modal, .add-savings-modal, .transaction-success-modal, .loan-summary-modal, .loan-success-modal, .profile-update-modal {
        width: 500px;
    }
}


/* CSS for screens between 576px and 767.98px */
@media (min-width: 577px) and (max-width: 768.98px) {
    
    .addcard-info li {
        text-align: justify;
    }
}

/* CSS for screens up to 575.98px */
@media (max-width: 576.98px) {
    .success-modal, .otp-modal, .add-savings-modal, .transaction-success-modal, .loan-summary-modal, .loan-success-modal, .profile-update-modal {
        width: 450px;
    }
    .add-savings-modal .btn {
        width: fit-content;
    }
    .savings-modal-form-text{
        font-size: 16px;
    }
    .addcard-info li {
        text-align: justify;
    }
    .addcard-info {
    }
    .loan-summary-modal hr {
        width: auto;
    }
    .profile-update-modal .btn {
        width: fit-content;
    }
    .transaction-success-modal .btn{
        width: fit-content;
    padding: 10px;

    }
    .loan-summary-modal .btn{
        width: fit-content;
        padding: 10px;
    }
}

@media (max-width: 426.98px) {
    .success-modal, .otp-modal, .add-savings-modal, .transaction-success-modal, .loan-summary-modal, .loan-success-modal, .profile-update-modal {
        width: 380px;
    }
    .add-savings-modal input, .add-savings-modal select {
        width: 300px;
    }
    .add-savings-modal p, .transaction-success-modal p, .loan-success-modal p {
        width: 80%;
    }
    .add-savings-modal .btn {
        font-size: 18px;
    }
    .add-savings-modal .add-savings-div{
        width: 750px;
    }
   .success-modal .btn{

   }
}

/* Small Devices (Phones) */
@media (max-width: 376px){
    .success-modal, .otp-modal, .add-savings-modal, .transaction-success-modal, .loan-summary-modal, .loan-success-modal, .profile-update-modal {
        width: 350px;
    }
    .add-savings-modal input, .add-savings-modal select {
        width:250px;
    }
    .add-savings-modal p, .transaction-success-modal p, .loan-success-modal p {
      
        font-size: 16px ;
    }
    .add-savings-modal.p-5 {
        padding: 2rem 1rem !important;
    }
    .profile-update-modal p {
        text-align: center;
    }
    .transaction-success-modal .icon {
        width: 50px;
        height: 50px;
        color: #2F9909;
    }
}

@media (max-width: 321px) {
    .add-savings-modal input, .add-savings-modal select {
        width: 200px;
    }
    .success-modal, .otp-modal, .add-savings-modal, .transaction-success-modal, .loan-summary-modal, .loan-success-modal, .profile-update-modal {
        width: 270px;
    }
    .add-savings-modal .btn {
        font-size: 16px;
    }
    .loan-info li{
        flex-direction: column;
        margin-top: 0 !important;
        margin-bottom: 0.5rem !important;
    }
   
}