.login-form-page {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url('/src/assets/bg.png');
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center;
    background-size: cover;

}

.login-page.mt-3 {
    margin-top: 2rem !important;
}

a {
    text-decoration: none;
}

.login-form-page a:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #0D9068;
    text-wrap: nowrap;
    line-height: 0;
}
.login-button, .register-link-btn, .register-link-btn:hover {
    width: 160px;
    padding: 10px;
    background-color: transparent;
    border: 1.3px solid #FFFFFF;
    border-radius: 55px;
    font-weight: 400;
    font-size: 20px;
    line-height: normal;
text-wrap: nowrap;
    color: #FFFFFF;
}
.admin-form{}



.login-form-page .reg-header .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-wrap: nowrap;
    color: #FFFFFF;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}

.login-page .form {
    width: 500px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 55px #000000;
    border-radius: 15px;
    padding: 20px 0;
}

.login-page h1 {
    font-size: 30px;
    color: #000000;
}

.login-page p {
    font-weight: 400;
    font-size: 15px;
    color: #444444;
    white-space: nowrap;
    
}
.login-page .password-match{
    color: red;
}

.login-page input.email-input {
    /* width: 400px; */
    border: 1px solid #444444;
    border-radius: 8px;
    padding: 10px;
    margin: 3px 2px;
}

.login-page .password {
    /* width: 400px; */
    border: 1px solid #444444;
    border-radius: 8px;
    padding: 2px 10px;
    margin: 3px 2px;
}

.password input,
.password input:hover,
.password input:focus {
    flex: 1;
    outline: none;
    border: none;
    background-color: transparent;

}

.password input[type=text]:enabled {
    background: transparent;
}

.password input[type=text]:disabled {
    background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.login-page input.email-input:hover,
input.email-input:focus,.login-page .password:focus-within {
    outline: 1px solid #FB9129;
    border: 1px solid #FB9129;
}



.login-page input[type=checkbox]:hover {
    border: none;
    outline: none;
}

.login-page input[type=checkbox] {
    width: 18px;
    height: 18px;
    accent-color: #FB9129;
    color: #fff;

}

.login-page input[type=checkbox]::after {
    content: "";
    color: #FFFFFF;
}

.form .login-button, .form .login-button:hover {
    background: #0D9068;
    color: #FFFFFF;
}
.login-page .otp-input{
    text-wrap: nowrap;
}
.login-page .otp-input input {
    width: 50px;
    height: 50px;
    background: #EFEFEF;
    border-radius: 10px;
    border: 0.5px solid #ADADAD;
    font-size: 18px;
    text-align: center;
    line-height: normal;
    margin: 0.5rem
}

.login-page .otp-input input::-webkit-outer-spin-button,
.login-page .otp-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

p.create {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
}

.login-page .btn.profile-saved {
    width: 400px;
    /* height: 45px; */
    background: #444444;
    border-radius: 4px;
    color: #FFFFFF;
}

.login-page .btn.profile-saved p {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0 10px;
}

@media (min-width: 769px) and (max-width: 900px) {
    .login-form-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .reg-header a.mx-5 {
        margin-right: 2rem !important;
        margin-left: 2rem !important;
    }

}


/* CSS for screens between 576px and 767.98px */
@media (min-width: 600px) and (max-width: 768.98px) {
    .login-form-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .reg-header-content {
        align-items: center !important;
    }
    .reg-header a.mx-5 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .register-link-btn {
        width: 135px;
    }
    .login-page h1{
        text-align: center;
    }
}

/* CSS for screens up to 575.98px */
@media (min-width: 576px) and (max-width: 599.98px) {
    .register-link-btn {
        width: fit-content;
        font-size: 16px;
        padding: 5px 10px;
        margin-top: 5px;
    }
    .login-page h1{
        text-align: center;
    }
    .login-page .form {
        padding: 20px !important;
    }
    .login-form-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    
}

/* Small Devices (Phones) */
@media (min-width: 426px) and (max-width: 575.98px) {
    .login-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .login-form-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .reg-header a.mx-5 {
        margin-right: 0rem !important;
        margin-left: 0rem !important;
    }
    .reg-header-content{
        align-items: center !important;
    }
    .register-link-btn {
        width: fit-content;
        font-size: 16px;
        padding: 5px 10px;
        margin-top: 5px;
    }
    .login-page h1{
        text-align: center;
    }
    .login-page .form {
        padding: 20px !important;
    }
    .login-page .otp-input input {
        width: 45px;
        height: 45px;
        font-size: 16px;
        margin: 0.3rem
    }
    .login-button, .login-button:hover {
        width: 140px;
        font-size: 16px;
    }
}

@media (min-width: 376px) and (max-width: 426.98px) {
    .reg-header-content{
        /* margin: auto 26px !important; */
    }
    .login-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .login-form-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .reg-header a.mx-5 {
        margin-right: 0rem !important;
        margin-left: 0rem !important;
    }
    
    .register-link-btn {
        width: fit-content;
        font-size: 14px;
        padding: 5px 10px;
        margin-top: 5px;
    }
    .login-page .form {
        width: 350px;
        margin: 0 auto;
    }
    .login-page h1 {
        font-size: 25px;
    }
    .form{
        text-align: center;
    }
    .login-page .otp-input input {
        width: 45px;
        height: 45px;
        font-size: 16px;
        margin: 0.3rem
    }
    .login-page .form {
        padding: 20px !important;
    }
    .login-form-page .reg-header .text {
        flex-direction: column;
        align-items: center;
    }
    .login-page p {
        white-space: wrap;
    }
    .login-button, .login-button:hover {
        width: 130px;
        font-size: 16px;
    }
}

@media (max-width: 375.98px) {
    .login-form-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
   .login-page .reg-header a.mx-5 {
        margin-right: 0rem !important;
        margin-left: 0rem !important;
    }
    .login-page.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .reg-header-content{
        align-items: flex-start !important;
        
    }
    .login-page p {
        white-space: wrap;
    }
    .register-link-btn {
        width: fit-content;
        font-size: 14px;
        padding: 5px 10px;
        margin-top: 5px;
    }
    .login-form-page .reg-header a, .login-form-page  .reg-header .text {
        font-size: 14px;
    }
  
    .login-form-page .reg-header .text {
        flex-direction: column;
        align-items: center;
        /* text-wrap: wrap; */
        text-align: center;
    }
    .login-form-page .reg-header-content{
        justify-content: space-between !important;
        margin: auto 0px !important;
    }
    .login-page .form {
        width: 320px;
        margin: 0 auto;
        padding: 20px !important;
    }
    .login-page h1 {
        font-size: 25px;
    }
    .form{
        text-align: center;
    }
    .login-page .otp-input input {
        width: 40px;
        height: 40px;
        font-size: 14px;
        margin: 0.3rem
    }
    .login-button, .login-button:hover {
        width: 130px;
        font-size: 16px;
    }
    
}

@media (max-width: 321px) {
    .login-page .form {
        width: fit-content;
        padding: 20px !important;
        
    }

    .login-page.otp-page .form {
        width: 280px;
    }
    .login-page .otp-input input {
        width: 35px;
        height: 35px;
        font-size: 14px;
        margin: 0.3rem
    }
    
    .form{
        font-size: 14px;
    }
    .login-page h1 {
        font-size: 20px;
    }
    
    .reg-header-content{
        margin: auto !important;
    }
   
    .login-form-page .reg-header a, .login-form-page  .reg-header .text {
        font-size: 12px;
    }
    .login-form-page .reg-header .text {
        width: 130px;
    }
}