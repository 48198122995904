.support {

    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
}

.content {
    /* padding: 0 !important; */
}

.support h1 {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 20px;
    color: #0D9068;
}

.support-tab {
    border-bottom: 1px solid #E98923;
    ;
}

.support-tab .tab-item {
    text-decoration: none;
    padding: 3px 20px;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
}

.support-tab .tab-item:hover {
    cursor: pointer;
}

/* .support-tab a.active {
    padding: 5px;
    border-bottom: 4px solid #FB9129;
    border-radius: 3px 3px 0px 0px;
} */
.support-tab .tab-item.active {
    font-weight: 500;
    border-bottom: 3px solid #FB9129;
    border-radius: 3px 3px 0px 0px;
}

@media screen and (max-width: 769px) {
    .support h1 {
    font-size: 30px;
    margin-bottom: 0px;
}
}

@media screen and (max-width: 600px) {
    .support-tab.my-5 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

}

@media screen and (max-width: 577px) {}
@media screen and (max-width: 426px) {
    .support-tab .tab-item {
        font-size: 16px;
    }
}

@media screen and (max-width: 376px) {
    .support h1 {
        font-size: 25px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 321px) {
    .support-tab .tab-item {
        font-size: 14px;
    }
}