.admin-profile-page {
  margin: 0 auto;
  padding: 15px 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-profile-page h1 {
  font-size: 35px;
  font-weight: 600;
  color: #0D9068;
  text-align: center;

}
.admin-profile-page .add-icon {
  background: #0D9068;
  color: #ffffff;
  margin: 0 5px;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.admin-profile-page .tabs {
  display: flex;

}

.admin-profile-page .tab {
  padding: 10px 20px;
  background-color: transparent;
  cursor: pointer;
  width: 100px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin: 0 50px;


}

.admin-profile-page .tab.active {
  background-color: #0D9068;
  color: #fff;

}

.admin-profile-page .content {
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 4px 45px 0px #0000001A;
  background: #FFFFFF;

  border-radius: 15px
}

.admin-profile-page .edit-profile {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.admin-profile-page .input-field {
  width: 345px;

  display: flex;
  flex-direction: column;
  margin: 10px 0;

}

.admin-profile-page .input-field input {
  outline: none;
  border-radius: 10px;
  border: 1px solid #FB9129;
  padding: 15px 10px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  opacity: 0.7;

}
.admin-profile-page .input-field input:focus {
  outline: none;
  border: 1.3px solid #0D9068;
 opacity: 1;

}

.input-container {
  position: relative;
  width: 200px;
  /* Set the desired width of the input field */
}


.admin-profile-page .profile-info {
  display: flex;
  align-items: center;
}



.admin-profile-page .image-preview {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.admin-profile-page .edit-icon {
  background-color: #FB9129;
  color: #e5e5e5;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;

}

.profile-picture {
  width: auto;
}

.admin-profile-page .camera-icon {
  position: absolute;
  color: #FB9129;
  background-color: #fff;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;
  bottom: 160px;
  left: 670px;
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  box-shadow: 0px 2px 10px 0px #00000040;

}

.admin-profile-page .camera-icon .iconify {
  font-size: 30px;
}
.admin-profile-data{
  margin-top: 20px;
}
.admin-profile-page .info {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.admin-profile-page .info .field {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;

}

.admin-profile-page .input-field label {
  font-weight: 700;
}

.admin-profile-page .info .field span {

  font-size: 14px;
  font-weight: 600;
}

.admin-profile-page .save-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #0d9068;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 50px
}

.admin-profile-page .input-field {}

.admin-profile-page .snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0d9068;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
}

@media screen and (max-width: 1024px) {
  .admin-profile-page .camera-icon {

    bottom: 330px;
    left: 550px;
  }
}

@media screen and (max-width: 992px) {
  .admin-profile-page .camera-icon {

    bottom: 320px;
    left: 530px;

  }
}

@media screen and (max-width: 800px) {
  .admin-profile-page .content {
    width: auto;
    margin: 1rem auto !important;
  }
  .admin-profile-page h1 {
    font-size: 30px;
  }
  .admin-profile-page .camera-icon {
    
    left: 425px;
}

}

@media screen and (max-width: 769px) {
  .admin-profile-page {
    padding: 10px;
  }
  .admin-profile-page h1 {
    font-size: 28px;
}
.admin-profile-page .camera-icon {
  left: 410px;
}
.admin-profile-page .ms-5{
  margin-left: 0.5rem !important
}
}

@media screen and (max-width: 600px) {
  .admin-profile-page .camera-icon {
    
    bottom: 325px;
    left: 325px;
  }
}
@media screen and (max-width: 522px) {
  .admin-profile-page .camera-icon {
 
  left: 295px;
}
}
@media screen and (max-width: 426px) {
  .admin-profile-data{
    display: flex;
    
  }
  .admin-profile-page .info {
    align-items: flex-start;
    flex-direction: column;
  }
  .admin-profile-page .info .field {
    margin: 5px 10px;
    font-size: 16px;
  }
  .admin-profile-page .image-preview {
    width: 125px;
    height: 125px;
  }
  .admin-profile-page .camera-icon {
    left: 240px;
    bottom: 350px;
    width: 35px;
    height: 35px;
}
.admin-profile-page .edit-icon {
  width: 35px;
  height: 35px;
}
.admin-profile-page .camera-icon .iconify {
  font-size: 25px;
}
.admin-profile-page .input-field {
  width: 280px;
}
.admin-profile-page h1 {
  font-size: 22px;
}
.admin-profile-page .tab {
  padding: 10px 15px;
  width: auto;
  margin: 0 30px;
  
} .admin-profile-page .edit-profile {
  
    padding: 10px;
  }

}

@media screen and (max-width: 376px) {
  .admin-profile-page h1 {
    font-size: 20px;
}
  .admin-profile-page .tab {
    font-size: 14px;
  }
 
  .admin-profile-page .input-field {
    width: 300px;
}
.admin-profile-page .image-preview {
  width: 115px;
  height: 115px;
}
.admin-profile-page .camera-icon {
  left: 215px;
  bottom: 370px;
  width: 30px;
  height: 30px;
}
.admin-profile-page .input-field input {
  padding: 10px 10px;
}
.admin-profile-page .edit-profile {
  padding: 10px 15px;
}
.edit-icon.ms-auto {
  margin-left: 225px!important;
}
}

@media screen and (max-width: 321px) {
  .admin-profile-data {
    display: revert;
}
.admin-profile-page .info {
 display: grid !important;
 grid-template-columns: 1fr 1fr;
 gap: 0 5px;
 margin: 8px 0;
}
.admin-profile-page .profile-info {
  width: 280px;
}
.admin-profile-page .content {
  padding: 20px 0;
}
  .admin-profile-page .camera-icon {
    left: 185px;
    bottom: 375px;
  }
  .admin-profile-page .image-preview {
    width: 110px;
    height: 110px;
}
  .admin-profile-page .input-field {
    width: 250px;
}
.admin-profile-page .input-field input {
  font-size: 14px;
}
}