/* NotificationPopUp.css */
/* .notification.pop-up {
    position: fixed;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    max-height: 500px;
    overflow-y: auto;
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 999;
  }
   */

  

   .ReactModal__Overlay.notification-overlay {
    width: 500px;
    padding: 20px;
    top: 5% !important;
    left: 800px !important;
    margin-left: 0 !important;
    position: fixed;
    inset: 0px;
    background-color: transparent !important;
    z-index: 999;
  }
  
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    box-shadow: 0px 3px 15px 0px #00000012;
    border-radius: 12px 12px 0px 0px
  }
  
  .mark-all-btn {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #444444;
  
  
  }
  
  .mark-all-btn:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .close-btn,
  .notification-dot .delete-icon {
    outline: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 24px;
    height: auto !important;
    width: auto !important;
    color: #0D9068;
    background-color: transparent !important;
    text-align: center;
  
  
  }
  
  
  
  .notification {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 10px 0;
    cursor: pointer;
  }
  
  
  .notification-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .notification-dot.unread {
    background-color: #FB9129;
    ;
  }
  .notification-dot.read {
    background-color: #4f4f4e;
    ;
  }
  
  
  
  .notification-content {
    flex: 1;
  }
  
  .notifications-title {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
  }
  
  .notifications-message {
    font-size: 12px;
    font-weight: 400;
  
  }
  
  .ReactModal__Content {
    height: fit-content !important;
    padding: 0 !important;
    overflow-y: auto;
  }
  
  .footer {
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
    cursor: pointer;
    color: #0D9068;
    font-weight: 600;
  }
  
  /* NotificationsPage.css */
  .notifications-page {
    padding: 20px;
  }
  
  .notifications-page h1 {
    margin-bottom: 20px;
    font-size: 36px;
  }
  
  .notifications-page ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .notifications-page li {
    margin-bottom: 10px;
    padding: 10px 0;
    border-radius: 3px;
    list-style: none;
  
  }
  
  
  
  .notification-title {
    font-size: 14px;
    font-weight: 400;
  
  }
  
  .notification-message {
    font-size: 12px;
    font-weight: 400;
    color: #444444;
    text-align: right;
  }
  .notifications-container .notification-message{
    text-align: left;
  }
  
  .backto-admin,
  .backto-admin:hover {
    text-decoration: none;
    background-color: #FB9129;
    padding: 10px 30px;
    border-radius: 20px;
    color: #ffffff;
  
  }
  
  @media screen and (max-width: 1024px) {
    .ReactModal__Overlay {
      padding: 20px;
      top: 5% !important;
      left: 527px !important;
      margin-left: 0 !important;
      position: fixed;
      inset: 0px;
    }
  }
  @media screen and (max-width: 992px) {
    .ReactModal__Overlay {
    left: 490px !important;
    }
  }
  @media screen and (max-width: 800px) {
    .ReactModal__Overlay {
      left: 290px !important;
      }
      .notifications-page h1 {
        font-size: 32px;
    }
  }
  
  @media screen and (max-width: 769px) {
    .notifications-page h1 {
      font-size: 28px;
  }
    .ReactModal__Overlay {
      left: 250px !important;
      }
  }
  
  @media screen and (max-width: 600px) {
    .ReactModal__Overlay {
      left: 100px !important;
  }
  .notifications-page h1 {
    font-size: 24px;
  }
  }
  @media screen and (max-width: 522px) {
    .ReactModal__Overlay {
      left: 10px !important;
      top: 0 !important;
      background-color: rgba(0, 0, 0, 0.9) !important;
  }
  }
  @media screen and (max-width: 426px) {
    .ReactModal__Overlay {
      width: auto;
      justify-content: center;
      align-items: center;
      display: flex;
      left: 0 !important;
  }
  .notifications-page h1 {
    font-size: 20px;
  }
  }
  
  @media screen and (max-width: 376px) {
    .notifications-page h1 {
      font-size: 18px;
  }
  }
  
  