.content {
    /* padding: 20px !important; */
}
.user-dashboard-home{
    margin: 70px 0 0 0; 
}
.rotatinglines {
    width: 10%;
    stroke: #1B7B44;
    stroke-width: 2
}

.user-dashboard-home .profile-info.mobile {
    display: none;
}

.user-info-summary{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.user-dashboard-home h1 {
    /* font-weight: 700; */
    font-size: 36px;
    margin-bottom: 20px;
    color: #0D9068;

}


.card.my-savings.active {
    background-color: #016b4b;

}

.user-home-welcome {
    width: fit-content;
    background: #FB9129;
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-home-welcome h4 {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    line-height: normal;
}

.user-home-welcome p {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: normal;
    margin-bottom: 0;
}


.savings-wallet-category {
    display: flex;
    white-space: nowrap;
    overflow-x: scroll !important;
    scrollbar-width: none;
    -ms-overflow-style: none;
    cursor: pointer;
    align-self: normal;
    flex: 1;
}

.savings-wallet-category::-webkit-scrollbar {
    display: none;
}

.savings-wallet-category .card,
.schedule-savings-page .card {
    flex: 0 0 200px;
    margin-right: 10px;
    background: rgba(13, 144, 104, 0.25);
    border-radius: 12px;
    position: static;
}

.savings-value,
.hidden-input {
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    color: #E98923;
    line-height: normal;
}

.user-dashboard-home .card p {
    font-size: 20px;
    text-align: center;
    color: #444444;
}

.user-dashboard-home input::placeholder {
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    color: #E98923
}

.user-dashboard-home input::-webkit-outer-spin-button,
.user-dashboard-home input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.user-dashboard-home .eye-icon {
    width: 25px;
    height: 25px;
}

.user-dashboard-home .card p:last-child {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #444444;
}

.btn.addsaving-btn {
    width: 150px;
    padding: 10px 8px;
    background: #0D9068;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    white-space: nowrap;
    margin: 10px 8px;
}

.btn.addsaving-btn .add-icon {
    width: 22px;
    height: 22px;
}

.transaction-history h1 {
    font-weight: 700;
    font-size: 32px;

    color: #0D9068;
}

.transaction-history .add-icon {
    background: #0D9068;
    color: #ffffff;
    margin: 0 5px;
    border-radius: 50px;
}
.top-nav-btn{
    align-items: center;
}

.top-nav p {
    font-weight: 600;
    font-size: 20px;
    text-align: center;

    color: #444444;
}

.sort {
    background: #FFFFFF;
    border: 1.5px solid #E1E1E1;
    border-radius: 8px;

}

.sort select {
    width: 140px;
    height: 40px;
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    color: #313A4E;
    appearance: none;
    background-image: url('../../../../assets/outline-arrow.png');
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: 1em auto, 100%;
}

.sort hr {
    height: 23px;
    transform: rotate(180deg);
    margin: 0 0.6rem;
    border: 1px solid #E1E1E1;
}

.sort .arrow-icon {
    width: 40px;
    height: 40px;
}


.dropdown-container {
    height: fit-content;
    padding: 0 10px;
    background: #FFFFFF;
    border: 1.5px solid #E1E1E1;
    border-radius: 8px;
}

.dropdown-header {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-header span {
    margin-right: 5px;
}

.dropdown-header span {
    font-weight: 400;
    font-size: 14px;
    color: #313A4E;
}

.arrow {
    display: contents;
    font-size: 25px;
    transition: transform 0.3s ease;
}

.dropdown-content {
    position: absolute;
    min-width: 65px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 0 10px;
    display: none;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
}

.dropdown-content.show {
    display: block;
}

.dropdown-content span {
    display: block;
    margin: 10px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #000000;
}

.dropdown-content span:hover {
    cursor: pointer;
}

.dropdown-content hr {
    margin: 5px 0;
    border: none;
    border-top: 1px solid #ccc;
}





.download-icon {
    width: 42px;
    height: 42px;
    background: rgba(186, 190, 200, 0.25);
    border-radius: 50px;
    cursor: pointer;
}

.download-icon .icon {
    width: 30px;
    height: 30px;

    color: #FB9129;
}

.btn.view-all-btn {
    width: 100px;
    padding: 10px;
    background: #0D9068;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}



input[type=checkbox],
input[type=checkbox]:hover,
input[type=checkbox]:focus {
    width: 18px;
    height: 18px;
    background-color: #F6F9FF !important;
    color: #fff;
}

.table {
    margin-bottom: 0;
}

.css-1ygcj2i-MuiTableCell-root {
    font-weight: 500;
    font-size: 18px !important;
    color: #6B6F7B !important;
}

.css-1ex1afd-MuiTableCell-root {
    font-weight: 400;
    font-size: 16px !important;
    color: #444444;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    display: flex;
    /* justify-content: center; */
    /* width: 1000px !important; */
    background-color: #F6F9FF !important;
    border-radius: 20px 20px 0px 0px !important;
    box-shadow: none !important;
}

p.MuiTablePagination-selectLabel,
p.MuiTablePagination-displayedRows {
    margin: 5px;
}

.MuiBox-root {
    background-color: #F6F9FF
}






@media (max-width: 800px) {
    .user-dashboard-home {
        margin: 90px 0 0 0;
    }

    .user-dashboard-home .profile-info.mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .user-dashboard-home .profile-img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
        cursor: pointer;
      
      }
      .user-dashboard-home .btn.camera {
        margin-top: -20px;
        margin-left: 31px;
        display: flex;
        padding: 0;
      }

      .user-dashboard-home .camera .camera-icon {
        /* height: 16px; */
        /* width: 16px; */
        font-size: 20px;
        background-color: #fff;
        background: #FFFFFF;
        border: 1px solid rgba(68, 68, 68, 0.16);
        border-radius: 50%;
        padding: 2px;
    }
     
      
      .profile-info p {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #000000;
        margin: 0;
        overflow-y: hidden;
      }
}



@media (max-width: 769px) {

    .savings-value,
    .hidden-input {
        font-size: 20px;
    }

    .user-dashboard-home .eye-icon {
        width: 20px;
        height: 20px;
    }

    .user-dashboard-home .card p {
        font-size: 18px;
    }

    .user-dashboard-home h1 {
        font-size: 30px;
        margin-bottom: 0px;
    }
    .user-dashboard-header{
        flex-direction: column;
    align-items: flex-end !important;
    }
    .btn.addsaving-btn {
        width: auto;
        padding: 10px;
        margin: 8px 0 0 auto;
    }
}

@media (min-width: 579px) and (max-width: 600px) {
    
    .user-dashboard-header {
        flex-direction: column;

        align-items: flex-end !important;
    }
    .transaction-history h1 {
        font-size: 26px
    }
    
}

@media (max-width: 578.98px) {
   



    .css-1ex1afd-MuiTableCell-root {
        padding: 15px 12px !important;
    }
    .transaction-history h1 {
        font-size: 26px
    }
}
@media (min-width: 450px) and (max-width: 577.98px) {
    
  
    .savings-wallet-category .card{
        flex: 0 0 150px;
    }
    .top-nav{
        flex-direction: column;
    align-items: flex-start !important;
    }
    .top-nav .sort.mx-2 {
        margin: 0 !important;
    }
    .transaction-history h1 {
        font-size: 26px
    }
    .transaction-history.px-3 {
        padding-right: 0rem !important;
        padding-left: 0rem !important;
    }

}

@media  (max-width: 426.98px) {
    .top-nav-btn {
        margin-top: 1.5rem !important;
    }
   
    .savings-wallet-category .card{
        flex: 0 0 150px;
    }
    .top-nav{
        flex-direction: column;
    align-items: flex-start !important;
    }
    .top-nav .sort.mx-2 {
        margin: 0 !important;
    }
    .transaction-history h1 {
        font-size: 24px
    }
    .download-icon {
        width: 36px;
        height: 36px;
    }
    .download-icon .icon {
        width: 25px;
        height: 25px;
    }
    .transaction-history.px-3 {
        padding-right: 0rem !important;
        padding-left: 0rem !important;
    }
    .user-info-summary-text{
        flex-direction: column;
    align-items: flex-end;
    }
}

@media (max-width: 376px) {
    .user-dashboard-home h1 {
        font-size: 25px;
        margin-bottom: 10px;
    }
    /* .btn.addsaving-btn {
        width: auto;
    } */
    .top-nav .sort select {
        width: 100px;
    }
    .download-icon .icon {
        width: 25px;
    }
    .download-icon {
        width: 36px;
        height: 36px;
    }
    .btn.view-all-btn {
        width: auto;
        padding: 8px 10px;
        font-size: 14px;
    }
    .transaction-history h1 {
        font-size: 20px;
    }
    .download-icon .icon {
        width: 20px;
        height: 20px;
    }
    .profile-info p {
        font-size: 14px;

    }
}

@media (max-width: 321px) {}



/* @media screen and (max-width: 1024px) {
    .help .contact a {
    color: #0D9068;
}
}
@media screen and (max-width: 991px) {}
@media screen and (max-width: 769px) {
    
}
@media screen and (max-width: 600px) {}
@media screen and (max-width: 577px) {
    .user-dashboard-home{
    }
}
@media screen and (max-width: 376px) {}
@media screen and (max-width: 321px) {} */