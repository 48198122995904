.content {
    /* width: auto;
    margin-left: 200px;
    padding: 20px;
    overflow-y: auto; */
}

.withdrawal-form {
    flex: 8;
    margin: 50px 0 0 0;
    height: 100vh;
}

.withdrawal-form h1 {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 20px;
    color: #0D9068;
}

.withdrawal-form .card {
    width: 250px;
    background: rgba(13, 144, 104, 0.25);
    border-radius: 12px;
    margin: auto 8px;
    padding: 5px;
}

.withdrawal-form input,
.withdrawal-form input:hover {

    width: auto;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 25px;
    color: #E98923;
    background-color: transparent;
}

.withdrawal-form input:focus,
.withdrawal-input-form select:focus {
    outline: none;
}

.withdrawal-form .card p {
    font-size: 20px;
    text-align: center;

    color: #444444;
}



.withdrawal-form .eye-icon {
    width: 25px;
    height: 25px;
    /* margin-right: 50px; */
}

.withdrawal-form .card p:last-child {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    color: #444444;
}

.withdrawal-form p .note {
    font-weight: 400;
    font-size: 13px;
    color: #444444;

}

.withdrawal-input-form input,
.withdrawal-input-form select,
.withdrawal-input-form input:hover {
    max-width: 100%;
    height: 45px;
    width: 380px;
    border: 0.3px solid #909090;
    border-radius: 4px;
    margin: 15px 0;
    padding: 10px;
    color: #444444;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.7;
}

.withdrawal-input-form input::placeholder,
.withdrawal-input-form select option {
    font-size: 14px;
    color: #444444;
}

.withdrawal-input-form select {
    appearance: none;
    background-image: url('../../../../assets/filled-arrow.png');
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: 1em auto, 100%;

}

.withdrawal-input-form input::-webkit-outer-spin-button,
.withdrawal-input-form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* 
.withdrawal-input-form input, .withdrawal-input-form select, .withdrawal-input-form input:hover{
    height: 60px;
width: 365px;
border: 1px solid #909090;
border-radius: 6px;
margin: 15px auto;
padding: 10px;
}
.withdrawal-input-form input::placeholder, .withdrawal-input-form select option{
    font-size: 14px;
color: #444444;
}
.withdrawal-input-form input::-webkit-outer-spin-button,
.withdrawal-input-form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

.withdrawal-form .submit-btn {
    width: 180px;
    height: 45px;
    background: #FB9129;
    border-radius: 4px;
    font-size: 20px;
    color: #FFFFFF;
}


@media screen and (max-width: 800px) {
    .withdrawal-form {
    margin: 70px 0 0 0;
    }
}



@media (min-width: 579px) and (max-width: 600px) {
    .withdrawal-form h1 {
        font-size: 30px;
    }

    .withdrawal-form .submit-btn {
        width: 100px;
    }

}

@media (max-width: 578.98px) {
    .withdrawal-form h1 {
        font-size: 30px;
    }
    .withdrawal-form .submit-btn {
        width: 100px;
    }
}

@media (min-width: 450px) and (max-width: 577.98px) {
    .withdrawal-form h1 {
        font-size: 28px;
    }

    .withdrawal-form .submit-btn {
        width: 100px;
    }
}

@media (max-width: 426.98px) {
    .withdrawal-form h1 {
        font-size: 26px;
    }
    .withdrawal-form .card {
        margin: 0 10px 0 0;
    }
    .withdrawal-form .savings-wallet-category .card, .schedule-savings-page .card {
        flex: 0 0 200px;

    }
}

@media screen and (max-width: 376px) {
    .content {
        /* padding: 10px 0; */
    }

    .withdrawal-form h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .guarantor-account-form p {
        font-size: 18px;
        margin: 10px;
    }

    .withdrawal-form .submit-btn {
        width: 100px;
        font-size: 18px;
    }


}

@media screen and (max-width: 321px) {
    .withdrawal-form h1 {
        font-size: 20px;
    }

    .withdrawal-form .submit-btn {

        font-size: 16px;
    }
}