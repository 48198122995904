
.profile-update{
    margin: 70px 0 0 0;
}
.profile-update h1 {
    /* font-weight: 700; */
    font-size: 36px;
    margin-bottom: 20px;
    color: #0D9068;
}
.sticky-edit-button {
    position: sticky;
    top: 0; /* Adjust as needed */
    right: 0; /* Position it on the right side */
  
   
}



.profile-form {
    position: relative;
    width: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    border: none;
}

.profile-form p {
    font-weight: 700;
    font-size: 22px;
    color: #444444;
    margin: 10px 15px;
    text-align: left;
}
.profile-form form {
    position: relative;
}

.profile-form .form-group label {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin: 10px 0;
}

.profile-form .form-group input,
.profile-form select {
    max-width: 100%;
    height: 40px;
    width: 270px;
    background: rgba(217, 217, 217, 0.3);
    border: 0.3px solid #909090;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(68, 68, 68, 0.7);
    /* color: #444444; */
    /* opacity: 0.7; */
    padding: 10px;

}
.editable-input {
    border: 1px solid #0D9068 !important;
    background-color: #FFFFFF !important;
  }



.profile-form select {
    appearance: none;
    background-image: url('../outline-arrow.png');
    background-repeat: no-repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: 1em auto, 100%;


}

.profile-form .form-group input:focus,
.profile-form select:focus {
    outline: none;
    /* border: none; */
}

.profile-form input::-webkit-outer-spin-button,
.profile-form input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.profile-form .btn {
    width: 180px;
    height: 50px;
    background: #FB9129;
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;

}



.profile-form .btn.edit {
    width: 180px;
    height: 50px;
    background-color:  #FB9129;
        color:#fff;
    font-size: 20px;
    border: 2px solid #FB9129;
}

.profile-form .btn.discard {
    width: 180px;
    height: 50px;
    background-color:#FFFFFF;
        color:#FB9129;
    font-size: 20px;
    border: 2px solid #FB9129;
    white-space: nowrap;
}

.profile-form .btn.profile-saved {
    width: 425px;
    /* height: 45px; */
    background: #444444;
    border-radius: 4px;
    color: #FFFFFF;
}

.btn.profile-saved .btn-icon {
    background-color: #0d9068;
    color: #FFFFFF;
    font-size: 28px;
    /* width: 25px;
    height: 25px; */
    /* padding: 2px; */
    font-weight: bolder;
    border-radius: 50px;
}

.profile-form .btn.profile-saved p {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0 10px;
}




@media (min-width: 579px) and (max-width: 600px) {
    .profile-update h1 {
        font-size: 30px;
    }
    .profile-update.px-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .profile-form.px-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .profile-form p {
        font-size: 20px;
        margin: 10px;
    }
    .profile-form .btn.edit,
    .profile-form .btn {
        width: 100px;
    }
  
}

@media screen and (max-width: 578.98px) {
    .profile-update h1 {
        font-size: 30px;
    }
    .profile-update.px-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .profile-form.px-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .profile-form p {
        font-size: 20px;
        margin: 10px;
    }
    .profile-form .btn.edit,
    .profile-form .btn {
        width: 100px;
    }
}
@media (min-width: 450px) and (max-width: 577.98px) {
    .profile-update h1 {
        font-size: 28px;
    }
    .profile-update.px-4 {
        padding-right: 0rem !important;
        padding-left: 0rem !important;
    }
    .profile-form.px-4 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .profile-form p {
        font-size: 20px;
        margin: 10px;
    }
    .profile-form .form-group input, .profile-form select {
        width: auto;
    }
    .profile-form .btn.edit,
    .profile-form .btn {
        width: fit-content;
    }
}

@media  (max-width: 426.98px) {
    .profile-update h1 {
        font-size: 26px;
    }
    .profile-form .form-group input, .profile-form select {
        width: auto;
    }
    .profile-form .btn, .profile-form .btn.discard, .profile-form .btn.edit  {
        
        font-size: 16px;
    }
}

@media screen and (max-width: 376px) {
    .content {
        /* padding: 10px 0; */
    }
    .profile-update h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .profile-form p {
        font-size: 18px;
        margin: 10px;
    }
    .profile-form .btn, .profile-form .btn.discard, .profile-form .btn.edit  {
        
        font-size: 14px;
    }
    .profile-form .btn{
        width: 100px;
    }
    .profile-form .btn.discard.my-4{
        width: auto;
        /* margin-bottom: 0rem !important; */
    }
}

@media screen and (max-width: 321px) {
    .profile-update h1 {
        font-size: 20px;
    }
    .profile-form .btn, .profile-form .btn.discard, .profile-form .btn.edit  {
        
        font-size: 16px;
    }
    .profile-form .btn.discard.my-4{
        margin-bottom: 0rem !important;
    }
}



/* @media screen and (max-width: 1024px) {}
@media screen and (max-width: 991px) {}
@media screen and (max-width: 769px) {}
@media screen and (max-width: 600px) {}
@media screen and (max-width: 577px) {
    .user-dashboard-home{
    }
}
@media screen and (max-width: 376px) {}
@media screen and (max-width: 321px) {} */