.faq .accordion-header {
    width: auto;
    display: flex;
    align-items: center;
    padding: 10px 0;
    justify-content: space-between;
    /* border-bottom: 1px solid #000; */
}

.faq .faq-list .accordion-header p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    color: #000000;
}

.faq .accordion-header .faq-icons {
    width: 25px;
    height: auto;
    /* margin: 0 100px; */
    color: #FB9129;
    ;
}

.faq .accordion-content p {
    width: auto;
    font-size: 14px;
    text-align: justify;
    margin: 5px 0;
    color: #444444;

}

@media screen and (max-width: 427px) {
    .faq-list.mt-5 {
        margin-top: 2rem !important;
    }
    .faq-p{
        text-align: justify;
    }
}
@media screen and (max-width: 376px) {}
@media screen and (max-width: 321px) {
    .faq-p{
        font-size: 14px;
    }
}