.user-profile {
  /* display: flex; */
  /* height: 100vh; */
}

.go-back.mobile {
  display: none;
}

.sidebar {
  position: fixed;
  width: 200px;
  height: 85vh;
  max-height: 90vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px #0000000D;
  border: 1px solid #44444440;
  z-index: 800;
}

.sidebar p {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #000000;
  text-align: center;
  padding: 5px 15px;
  margin: 10px 0;
}

.sidebar p.active,
.sidebar p:hover {
  display: flex;
  align-items: center;
  width: 150px;
  height: auto;
  border-radius: 33px;
  color: #0D9068;
  background: #0d906949;
}

.admin-site .content {
  width: auto;
  margin-left: 200px;
  padding: 20px 10px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.profile h4.go-back {
  font-size: 20px;
  font-weight: 500;
  color: #0D9068;


}

.back-icon {
  font-size: 25px;
  background-color: #0D9068;
  color: #ffffff;
  border-radius: 20px;

}

.user-info-section {
  width: auto;
  height: auto;
  background-color: #FFFFFF;
  border: 1px solid #44444440;
  border-radius: 15px;
}

.profile p.h6,
.loan p.h6 {
  font-size: 20px;
  font-weight: 600;
  color: #0D9068;

}

.personalinfo-user .fw-bold{
  text-align: left;
  text-wrap: nowrap;
}

.personalinfo-user {
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.g-account .personalinfo-user {
  gap: 0 20px;
} 

.loan .css-1ygcj2i-MuiTableCell-root,
.loan .css-1ex1afd-MuiTableCell-root {
  text-align: left !important;
  width: 200px !important;
}

.transaction-history .css-1ygcj2i-MuiTableCell-root,
.transaction-history .css-1ex1afd-MuiTableCell-root {
  text-align: left !important;
}

.loan .css-15wwp11-MuiTableHead-root {
  background: #F6F9FF;
  border-radius: 50px 50px 0 0 !important;
}


.loan .css-rqglhn-MuiTable-root {
  width: 90%;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #dbe7ff;
}

.profile p.info,
.loan p.info {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  white-space: nowrap;

}

.g-account.profile p.info {
  white-space: normal;
}

.profile p.info span {
  font-weight: 700;

}
.active-loan-info{
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
}

/* .profile span,
.loan span {
  font-size: 12px;
  font-weight: 400;
  color: #444444;

} */




.tab-headers .tab-header {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;
  color: #0D9068;
  border: 0;
}

.tab-headers .tab-header:hover {
  cursor: pointer;
}

.tab-headers .tab-header.active {
  border-bottom: 4px solid #0D9068;

}

.tab-header .notification {
  padding: 0 5px;
  background: #FB9129;
  border-radius: 100px;
  font-size: 12px;
  color: #FFFFFF;

}



.paid-icon {
  color: green;
  width: 20px;
  height: 20px;
  border-left: 1px solid #fff;
  cursor: pointer;
}

.active-icon {
  color: blue;
  width: 20px;
  height: 20px;
  border-left: 1px solid #fff;
  cursor: pointer;
}

.pending-icon {
  color: #e67e22;
  width: 20px;
  height: 20px;
  border-left: 1px solid #fff;
  cursor: pointer;
}
.rejected-icon {
  color: red;
  width: 20px;
  height: 20px;
  border-left: 1px solid #fff;
  cursor: pointer;
}
.pending-status {
  color: #e67e22;

  white-space: nowrap;
  margin: 0 2px;
}

.paid-status {
  color: green;

  white-space: nowrap;
  margin: 0 2px;
}

.active-status {
  color: blue;

  white-space: nowrap;
  margin: 0 2px;
}

.rejected-status {
  color: red;

  white-space: nowrap;
  margin: 0 2px;
}

/* .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 400px;
  max-width: 80%;
  outline: none;
}

.modal-content {
  margin-bottom: 20px;
}

.modal-content h2 {
  margin-bottom: 10px;
}

.modal-content p {
  margin-bottom: 5px;
}

.modal-content button {
  margin-right: 10px;
} */



@media screen and (max-width: 1024px) {
  .profile-stat-card {
    flex-direction: column;
  }

}

@media screen and (max-width: 992px) {}

@media screen and (max-width: 800px) {
  .user-profile {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */

  }

  .sidebar {
    position: relative;
    width: auto;
    height: auto;
    max-height: auto;
    padding: 10px 20px;
    display: flex;
    margin: 30px 14px 0 14px !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border-radius: 10px;
    box-shadow: none;
    border: none;
    z-index: 0;
  }

  .go-back.desktop {
    display: none;
  }

  .go-back.mobile {
    display: block;
  }

  .sidebar p.active,
  .sidebar p:hover {
    width: auto;
  }

  .g-account.profile p.info {
    /* white-space: nowrap; */
  }
}


@media screen and (max-width: 600px) {
  .personal-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-stat-card {
    flex-direction: row;
    margin: 0 !important
  }

  .g-account .personalinfo-user {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 522px) {
  .users-personal-info {
    flex-direction: column;

  }

  .users-personal-data {
    margin-top: 10px;
  }

  .users-personal-data .names {
    margin-left: 0 !important;
  }

  .personalinfo-user {
    grid-template-columns: 1fr 1fr;

  }

  .profile p.info {
    white-space: normal;
  }

  .g-account.profile p.info span {
    white-space: nowrap;
  }
}

@media screen and (max-width: 426px) {
  .sidebar {
    padding: 10px;
    margin: 5px 0 0 0 !important;
    justify-content: space-around;
  }

  .go-back.mobile {
    font-size: 20px;
  }

  .sidebar p {
    font-size: 14px;
    padding: 5px 10px;
    margin: 0;
  }

  .personalinfo-user {
    gap: 0 20px;
  }

  .profile p.info span {
    white-space: nowrap;
  }

  .profile p.h6,
  .loan p.h6 {
    font-size: 18px;
  }

  .g-account .personalinfo-user {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 376px) {
  .profile p.info span {
    white-space: normal;
  }

  .profile p.h6,
  .loan p.h6 {
    font-size: 16px;
  }

  .admin-site .content {
    padding: 20px 0px !important;
  }
}

@media screen and (max-width: 321px) {

  .sidebar p.active,
  .sidebar p:hover {
    font-weight: 700;
    width: auto;
    background: transparent;
  }

  .sidebar p {
    padding: 5px;
  }
  .g-account .personalinfo-user {
    grid-template-columns: 1fr;
}
}
