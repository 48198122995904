
.schedule-savings-page{
    margin: 70px 0 0 0;
}

.schedule-savings-page h1 {
    font-size: 32px;
    /* font-weight: 700; */
    line-height: normal;
    color: #0D9068;

}

.empty-schedule-savings {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 50vh;
    text-align: center;
}

.savings-icon {
    width: 60px;
    height: 60px;
    background: #FB9129;
    color: #fff;
    padding: 5px;
    border-radius: 50%;
}


.empty-schedule-savings p {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #444444;
    margin-top: 10px;
}

.empty-schedule-savings span {
    width: 350px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #444444;
}

.empty-schedule-savings .btn.schedule-savings-btn {
    width: 180px;
    padding: 10px;
    border-radius: 33px;
    background: #0D9068;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #FFFFFF;
}

.total-schedule-savings.card {
    background: #F6F9FF;
    width: fit-content;
}

.history-title {
    display: none;
}

.schedule-savings-history .css-1ygcj2i-MuiTableCell-root,
.schedule-savings-history .css-1ex1afd-MuiTableCell-root {
    text-align: left !important;
    width: 200px;
}

.schedule-savings-history .css-15wwp11-MuiTableHead-root {
    background: #F6F9FF;
    border-radius: 50px 50px 0 0;

}


.schedule-savings-history .css-rqglhn-MuiTable-root {
    width: 100%;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #dbe7ff;
}

.savings-plan-link h5 {
    font-size: 18px;
    /* font-weight: 600; */
    line-height: normal;
    color: #FB9129;
    cursor: pointer;
    white-space: nowrap;
}


.savings-plan-link p {
    width: 220px;

    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #444444;
}

.schedule-savings-page .card.my-savings.active {
    background-color: #015038;
}



/* CSS for screens between 992px and 1199.98px */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* CSS for screens between 768px and 991.98px */
@media (min-width: 769px) and (max-width: 900px) {
   
   
}

@media (min-width: 601px) and (max-width: 768.98px) {
    .schedule-savings-page h1 {
        font-size: 30px;
    }
    .schedule-savings-page{
      
        margin: 50px 0 0 0;
    }
}

/* CSS for screens between 576px and 767.98px */
@media (min-width: 577px) and (max-width: 600.98px) {
    .schedule-savings-page h1 {
        font-size: 30px;
    }
    .total-schedule-savings.card.p-3 {
        padding: 1rem !important;
        width: 200px;
    }
}

/* CSS for screens up to 575.98px */
@media (min-width: 428px) and (max-width: 576.98px) {
    .schedule-savings-page h1 {
        font-size: 28px;
    }
    .total-schedule-savings.card.p-3 {
        padding: 1rem !important;
        width: 200px;
    }
    .empty-schedule-savings span {
        width: auto;
    }
   
}

/* Small Devices (Phones) */
@media (min-width: 377px) and (max-width: 427.98px) {
    .schedule-savings-page h1 {
        font-size: 26px;
    }
    .schedule-savings-list .row{
        flex-direction: column-reverse;
    }
    .edit-savings-plans-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 25px;
        width: min-content;
    }
    .savings-plan-link p {
        width: fit-content;
    }
    .empty-schedule-savings span {
        width: auto;
    }
   
}

@media (max-width: 376.98px) {
   

    .schedule-savings-page h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .savings-icon {
        width: 50px;
        height: 50px;
    }
    .empty-schedule-savings span {
        width: auto;
    }
    .schedule-savings-list .row{
        flex-direction: column-reverse;
    }
    .edit-savings-plans-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 25px;
        width: min-content;
    }
    .savings-plan-link p {
        width: fit-content;
    }
    .savings-plan-link h5 {
        font-size: 16px;
    }
}

@media (max-width: 321px) {
    .schedule-savings-page h1 {
        font-size: 20px;
    }
    .savings-value, .hidden-input {
        font-size: 18px;
    }
    .edit-savings-plans-section {
        grid-template-columns: 1fr ;
        width: fit-content;
        grid-row-gap: 0px;
    }
    .savings-plan-link p {
        font-size: 12px;

    }
}