.guarantor {
  /* width: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  margin: auto; */
  background: #FFFFFF;
  box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.guarantor h1 {
  /* font-weight: 700; */
  font-size: 36px;
  color: #000000;
}

.guarantor p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #000000;
}

form.row {
  align-items: center;
  justify-content: center;
  width: fit-content;
  --bs-gutter-x: 0;
}

.custom-gutter {
  margin: 0 4rem;

}

.custom-gutter [class^='col-'] {
  margin: 15px 0;
}

.guarantor input,
.guarantor select {
  max-width: 80%;
  width: 400px;
  height: 50px;
  margin: 10px auto;
  padding: 8px;
  border: 1px solid #444444;
  border-radius: 4px;
  color: #444444;
  font-size: 15px;
}

.guarantor select {
  color: #727070;
}

.guarantor input::placeholder,
.guarantor select option {
  font-weight: 400;
  font-size: 14px;
}

.guarantor select {
  appearance: none;
  background-image: url('../../../../assets/outline-arrow.png');
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: 0.9em auto, 100%;


}

.guarantor input:focus,
.guarantor select:focus {
  outline: none;
}

.guarantor select.selected {
  color: #000;
  /* Change color to black */
}

.guarantor input::-webkit-outer-spin-button,
.guarantor input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




.guarantor-btn,
.guarantor-btn.prev:hover {
  width: 320px;
  height: 60px;
  background: #0D9068;
  border-radius: 55px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #FFFFFF;
}

.guarantor-btn:hover {
  background-color: #ffffff;
  color: #0D9068;
  border: 2px solid #0D9068;
}

.guarantor-btn.prev {
  background-color: #ffffff;
  color: #0D9068;
  border: 2px solid #0D9068;
}





/* CSS for screens between 768px and 900px */
@media (min-width: 769px) and (max-width: 900px) {
  .custom-gutter {
    margin: 0rem;
  }
}


/* CSS for screens between 576px and 767.98px */
@media (min-width: 577px) and (max-width: 768.98px) {
  .guarantor h1 {
    font-size: 30px;
  }

  .custom-gutter {
    margin: 0rem;
  }

  .guarantor-btn {
    width: 250px;
    height: 50px;
  }
}

/* CSS for screens up to 575.98px */
@media (min-width: 427px) and (max-width: 576.98px) {
  .guarantor-btn {
    width: 250px;
    height: 50px;
    padding: 5px;
  }
}

/* Small Devices (Phones) */
@media (min-width: 376px) and (max-width: 426.98px) {
  .guarantor h1 {
    font-size: 26px;
  }

  .guarantor span {
    font-size: 16px;
  }

  .custom-gutter {
    margin: 0rem;
  }

  .guarantor input,
  .guarantor select {
    width: 350px;
  }

  .guarantor-btn {
    width: 200px;
    height: auto;
    font-size: 20px;
    padding: 5px;
  }
}

@media (max-width: 375.98px) {
  .guarantor h1 {
    font-size: 24px;
  }

  .guarantor span {
    font-size: 14px;
    width: 75%;
  }

  .guarantor p {
    width: 75%;
  }

  .custom-gutter {
    margin: 0rem;
  }

  .guarantor input,
  .guarantor select {
    width: 350px;
  }

  .guarantor-btn {
    width: 200px;
    height: auto;
    font-size: 18px;
    padding: 5px;
  }

}

@media (max-width: 321px) {

  .guarantor input,
  .guarantor select {
    width: 320px;
  }
}