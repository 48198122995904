.farm {
    /* width: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    margin: auto; */
    background: #FFFFFF;
    box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.farm h1 {
    font-weight: 700;
    font-size: 36px;
    color: #000000;
}




.farm p {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #000000;
}

form.row {
    align-items: center;
    justify-content: center;
    width: fit-content;
    --bs-gutter-x: 0;
}

.custom-gutter {
    margin: 0 4rem;

}

.custom-gutter [class^='col-'] {
    margin: 15px 0;
}


.farm input {
    max-width: 80%;
  width: 400px;
  height: 50px;
  margin: 10px auto;
  padding: 8px;
  border: 1px solid #444444;
  border-radius: 4px;
  color: #444444;
  font-size: 15px;
}


.farm input::placeholder {
    font-weight: 400;
    font-size: 14px;
}



.farm input:focus {
    outline: none;
}

.farm input::-webkit-outer-spin-button,
.farm input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.farm form div {
    width: min-content;
    margin: 0 30px;
}


.farm-btn {
    width: 320px;
    height: 60px;
    background: #0D9068;
    border-radius: 55px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
}

.farm-btn:hover {
    background-color: #ffffff;
    color: #0D9068;
    border: 2px solid #0D9068;
}


/* .farm .modal {
    z-index: 999;
}

.farm .modal-base {
    top: 15%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fefefe;
    margin: auto;
    width: 800px;
    height: 400px;
    border-radius: 22px;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
}



.farm .modal-base_after-open {
    opacity: 1;
    transform: scale(1);
}

.farm .modal-base_before-close {
    transform: scale(.2);
    opacity: 0;
}

.farm .overlay-base {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(19, 18, 18, 0.75);
    opacity: 0;
    transition: all 0.3s ease-out;
}

.farm .overlay-base_after-open {
    opacity: 1;
}

.farm .overlay-base_before-close {
    opacity: 0;
} */



/* CSS for screens between 768px and 900px */
@media (min-width: 769px) and (max-width: 900px) {
    .custom-gutter {
        margin: 0rem;
    }
}


/* CSS for screens between 576px and 767.98px */
@media (min-width: 577px) and (max-width: 768.98px) {
    .farm h1 {
        font-size: 30px;
    }

    .custom-gutter {
        margin: 0rem;
    }

    .farm-btn {
        width: 250px;
        height: 50px;
    }
}

/* CSS for screens up to 575.98px */
@media (min-width: 427px) and (max-width: 576.98px) {
    .farm-btn {
        width: 250px;
        height: 50px;
        padding: 5px;
    }
}

/* Small Devices (Phones) */
@media (min-width: 376px) and (max-width: 426.98px) {
    .farm h1 {
        font-size: 26px;
    }

    .farm span {
        font-size: 16px;
    }

    .custom-gutter {
        margin: 0rem;
    }

    .farm input {
        width: 350px;
    }

    .farm-btn {
        width: 200px;
        height: auto;
        font-size: 20px;
        padding: 5px;
    }
}

@media (max-width: 375.98px) {
    .farm h1 {
        font-size: 24px;
    }

    .farm span {
        font-size: 14px;
        width: 75%;
    }

    .farm p {
        width: 75%;
    }

    .custom-gutter {
        margin: 0rem;
    }

    .farm input {
        width: 350px;
    }

    .farm-btn {
        width: 200px;
        height: auto;
        font-size: 18px;
        padding: 5px;
    }

}

@media (max-width: 321px) {

    .farm input {
        width: 320px;
    }
}